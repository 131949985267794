import { executeOnSingleOrMultiple, isInArray } from "tsparticles-engine";
import { Absorber } from "./Options/Classes/Absorber";
import { Absorbers } from "./Absorbers";
class AbsorbersPlugin {
    constructor() {
        this.id = "absorbers";
    }
    getPlugin(container) {
        return new Absorbers(container);
    }
    loadOptions(options, source) {
        var _a, _b;
        if (!this.needsPlugin(options) && !this.needsPlugin(source)) {
            return;
        }
        if (source === null || source === void 0 ? void 0 : source.absorbers) {
            options.absorbers = executeOnSingleOrMultiple(source.absorbers, (absorber) => {
                const tmp = new Absorber();
                tmp.load(absorber);
                return tmp;
            });
        }
        options.interactivity.modes.absorbers = executeOnSingleOrMultiple((_b = (_a = source === null || source === void 0 ? void 0 : source.interactivity) === null || _a === void 0 ? void 0 : _a.modes) === null || _b === void 0 ? void 0 : _b.absorbers, (absorber) => {
            const tmp = new Absorber();
            tmp.load(absorber);
            return tmp;
        });
    }
    needsPlugin(options) {
        var _a, _b, _c;
        if (!options) {
            return false;
        }
        const absorbers = options.absorbers;
        if (absorbers instanceof Array) {
            return !!absorbers.length;
        }
        else if (absorbers) {
            return true;
        }
        else if (((_c = (_b = (_a = options.interactivity) === null || _a === void 0 ? void 0 : _a.events) === null || _b === void 0 ? void 0 : _b.onClick) === null || _c === void 0 ? void 0 : _c.mode) &&
            isInArray("absorber", options.interactivity.events.onClick.mode)) {
            return true;
        }
        return false;
    }
}
export async function loadAbsorbersPlugin(engine) {
    const plugin = new AbsorbersPlugin();
    await engine.addPlugin(plugin);
}
export * from "./AbsorberContainer";
export * from "./Enums/AbsorberClickMode";
